import axios from "@/axios";

export default {
  fetchFile({ commit }, key) {
    if(key)
      return new Promise((resolve, reject) => {
        axios
          .post(`admin/upload/get`,{key})
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    else{
      return ""
    }
  },
};
