import state from "./modulePaymentPlansState.js";
import mutations from "./modulePaymentPlansMutations.js";
import actions from "./modulePaymentPlansActions.js";
import getters from "./modulePaymentPlansGetters";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
