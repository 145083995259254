import axios from "@/axios";

export default {
  fetchProductLegalsById({ commit },id) {
    return new Promise((resolve, reject) => {
      axios
        .get("paymentPlans/product-legals/"+id)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  

  calculateOnPayLater({ commit }, data) {
    return new Promise((resolve, reject) => {
      axios
        .post("calculation", data)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
};
