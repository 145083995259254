import state from "./moduleOnboardState";
import mutations from "./moduleOnboardMutation";
import actions from "./moduleOnboardAction";
import getters from "./moduleOnboardGetters";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
