import axios from "../../http/axios/index.js";

export default {

  SET_BEARER(state, accessToken) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
  },

  SET_INTRODUCER_LOGO(state, payload) {
    if (payload && payload.logo) {
      state.onboardingLogo = payload.logo;
    }

    if (payload && payload.introducerLogo) {
      state.introducerLogo = payload.introducerLogo;
    }
  },
};
