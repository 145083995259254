export default {
  pages: {
    key: "title",
    data: []
  },
  files: {
    key:"file_name",
    data: []
  },
  contacts: {
    key:"name",
    data: []
  },
}
