import store from "../store/store";

export const auth = (to, from, next, hasPassowrdBeenSet = true) => {
  const token = localStorage.getItem("accessToken");
  const publicPages = [
    "/login",
    "/set-password",
    "/forgot-password",
    "/verify-email/" + to.params.token,
    "/reset-password/" + to.params.token,
    "/pages/error-419",
    "/success"
  ];
  const authRequired = !publicPages.includes(to.path);

  if (authRequired && !token && to.name !== "user-reset-password") {
    store.state.redirectAfterLogin.name = to.name;
    store.state.redirectAfterLogin.params = to.params;

    if (from.path !== "/admin/login") {
      return next(hasPassowrdBeenSet ? "/login" : `/${to.params.introducerRequestedId}/authenticate`);
    }
  }

  return next();
};
