/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import onboardModule from "./onboardModule/moduleOnboard";
import plans from "./paymentplans/modulePaymentPlans";
import moduleAuth from "./auth/moduleAuth.js";
import s3Service from "./s3Service/moduleS3Service";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    auth: moduleAuth,
    onboardMerchant: onboardModule,
    plans,
    s3ServiceModule: s3Service
  },
  strict: process.env.NODE_ENV !== "production"
});
